import './scss/index.scss'
import WhatsappLogo from '../assets/svg/whatsapp.svg'

import { Fragment, render } from 'preact';

const App = () => {
  return (
    <>
      <header className="Header">
        <div className="Brand">
          <div className="Title">Lluis Colomer</div>
          <div className="Subtitle">Fisioterapia, Osteopatía y Acupuntura</div>
        </div>
      </header>
      <div className="Body">
        <div className="Content">
          <section className="Section Main">
            <div className="Background">
              <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="791.97" height="724.66" viewBox="0 0 791.97 724.66">
                <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" style="stop-color:rgb(255,255,255); stop-opacity:0" />
                  <stop offset="10%" style="stop-color:rgb(255,255,255); stop-opacity:0.1" />
                  <stop offset="100%" style="stop-color:rgb(255,255,255); stop-opacity:0.4" />
                </linearGradient>
              </defs>
                <circle cx="395.83" cy="360.83" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="145.23" cy="360.83" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="395.83" cy="505.56" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="646.74" cy="360.83" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="520.9" cy="289.96" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="269.3" cy="289.96" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="520.9" cy="579.43" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="269.3" cy="579.43" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="395.83" cy="216.1" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="520.9" cy="434.69" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="269.3" cy="434.69" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="269.3" cy="145.23" r="144.73" stroke="url(#gradient)" fill="none" />
                <circle cx="520.9" cy="145.23" r="144.73" stroke="url(#gradient)" fill="none" /></svg>
            </div>
            <div className="Text">
              Bienvenido a nuestro centro de terapias integrales, donde combinamos fisioterapia, osteopatía y acupuntura para ofrecerte un cuidado completo orientado al alto rendimiento deportivo y a la salud general. Especializados en la rehabilitación de lesiones deportivas, tratamiento del dolor y reequilibrio energético, nuestro objetivo es guiarte hacia un estado de bienestar integral.
            </div>
          </section>
          <section className="Section Performance">
            <div className="Title">Alto rendimiento deportivo</div>
            <div className="Text">Con experiencia en los Juegos Olímpicos de Beijing y el circuito profesional de tenis ATP-WTA, nuestro enfoque se centra en maximizar tu rendimiento deportivo. Ofrecemos programas personalizados de rehabilitación y prevención de lesiones, asegurando que estés siempre en tu mejor forma.</div>
          </section>
          <section className="Section Injuries">
            <div className="Title">Rehabilitación de Lesiones Deportivas</div>
            <div className="Text">Utilizamos técnicas avanzadas de fisioterapia y osteopatía para una recuperación óptima de lesiones deportivas. Nuestro enfoque integral acelera el proceso de curación, minimiza el riesgo de relesiones y mejora tu movilidad y fuerza.</div>
          </section>
          <section className="Section Pain">
            <div className="Title">Alivio del Dolor</div>
            <div className="Text">Nuestros tratamientos están diseñados para aliviar dolores musculares, articulares y somatizaciones físicas causadas por el estrés. A través de la acupuntura, osteopatía y técnicas especializadas, te ayudamos a liberarte del dolor y mejorar tu calidad de vida.</div>
          </section>
          <section className="Section Health">
            <div className="Title">Bienestar Integral</div>
            <div className="Text">Más allá del rendimiento deportivo y la rehabilitación, ofrecemos tratamientos para mejorar tu bienestar general, incluyendo insomnio, ansiedad, tinnitus, cuestiones ginecológicas y emocionales. Nuestra aproximación holística te ayuda a lograr un equilibrio entre cuerpo y mente.</div>
          </section>
          <section className="Section Energy">
            <div className="Title">Reequilibrio Postural y Energético</div>
            <div className="Text">A través de una valoración y reequilibrio postural personalizados, identificamos y corregimos desbalances que puedan afectar tu salud y rendimiento. Además, nuestros tratamientos de reequilibrio energético promueven una circulación energética óptima, vital para tu bienestar emocional y físico.</div>
          </section>
        </div>
        <div className="Section CallToAction">
          <div className="Text">¿Listo para comenzar tu camino hacia el bienestar? Contáctanos para agendar tu cita o saber más sobre nuestros servicios.
            <p>Estamos ubicados en:</p><p><a href="https://maps.app.goo.gl/TexggBEW42X1wm5QA">Carrer de francesc Carbonell 35 baixos 08034, Barcelona</a>.</p>
            <p className="Bye">¡Esperamos verte pronto!</p>
            <a href="https://wa.me/34663407440?text=Hola! Me gustaría pedir cita" target="_blank" className="Whatsapp__Wrapper">
              <div className="Whatsapp">
                <WhatsappLogo />
                <div className="Text">Pide tu cita por WhatsApp</div>
              </div>
            </a>
          </div>
        </div>

      </div>
      <footer className="Footer">
        <div className="Text">© 2024 Lluis Colomer. Todos los derechos reservados.</div>
      </footer>
    </>
  );
}

render(App(), document.body);